var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table',{attrs:{"columns":_vm.columns,"items":_vm.tasks,"loading":_vm.loading,"total-items":_vm.pagination.totalElements,"current-page":_vm.pagination.pageNumber,"total-pages":_vm.pagination.totalPages,"server-side-events":""},on:{"row-click":_vm.handlerRowClick,"on-sort":_vm.handlerSortTable,"change-page":_vm.handlerChangePage},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
var item = ref.item;
return [(item.pinned)?_c('r-icon',{attrs:{"icon":"pin"}}):_vm._e()]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('status-cell',{attrs:{"status":item.status}})]}},{key:"priority",fn:function(ref){
var item = ref.item;
return [_c('r-priority',{attrs:{"priority":item.priority.code}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('r-popover',{attrs:{"position":"bottom-left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('r-icon',{attrs:{"icon":"menu-context","fill":"rocky","size":"16"}})],1)]}}],null,true)},[_c('template',{slot:"default"},[_c('task-actions-wrapper',{attrs:{"task":item,"project-id":_vm.projectId},on:{"refetch-data":function($event){return _vm.$emit('refetch-table-data')}}})],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }