


































import { defineComponent, PropType } from "@vue/composition-api";
import draggable from "vuedraggable";

import KanbanCard from "@/app/components/Task/Kanban/KanbanCard.vue";
import { useRoute } from "@/shared/hooks";
import { TaskBoardColumn } from "@/shared/types/schema.types";

interface ChangeKanbanEvent {
  added: {
    element: TaskBoardColumn
  }
}

export default defineComponent({
  name: "Kanban",
  components: {
    KanbanCard,
    draggable,
  },
  props: {
    kanbanItems: {
      type: Array as PropType<TaskBoardColumn[]>,
      default: () => [],
    },
  },

  setup(_, { emit }) {
    const route = useRoute();
    const projectId = route.params.id;

    function handleChange(ev: ChangeKanbanEvent) {
      if (ev?.added) {
        emit('changed', ev.added.element);
      }
    }

    return {
      handleChange,
      projectId,
    };
  },
});
