import { render, staticRenderFns } from "./TaskActionsWrapper.vue?vue&type=template&id=050b4317&scoped=true&"
import script from "./TaskActionsWrapper.vue?vue&type=script&lang=ts&"
export * from "./TaskActionsWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050b4317",
  null
  
)

export default component.exports