













import { defineComponent, PropType } from "@vue/composition-api";

import { TaskViewSelectorEnum } from "@/shared/enums/components/Task/TaskViewSelector.enum";

export default defineComponent({
  name: "TaskViewSelector",
  props: {
    view: {
      type: String as PropType<TaskViewSelectorEnum>,
      required: true,
    },
  },
  setup(props) {
    const VIEW_SELECTOR_BUTTONS = [
      {
        value: TaskViewSelectorEnum.TASKS,
        icon: "list", 
      },
      {
        value: TaskViewSelectorEnum.KANBAN,
        icon: "icon-view", 
      },
      {
        value: TaskViewSelectorEnum.CALENDAR,
        icon: "calendar", 
      },
    ];

    function getBtnColor(val: string) {
      return props.view === val ? "primary" : "secondary";
    }

    function handlerChangeView(val: string): void {
      this.$emit("on-change", val);
    }
    return {
      VIEW_SELECTOR_BUTTONS,
      getBtnColor,
      handlerChangeView, 
    };
  },
});
