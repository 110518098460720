


















































import { useMutation } from "@vue/apollo-composable";
import { defineComponent, PropType, ref } from "@vue/composition-api";

import RPriority from "@/app/components/Base/Priority.vue";
import StatusCell from "@/app/components/Base/Status.vue";
import VTable from "@/app/components/Base/VTable/VTable.vue";
import IconSettings from "@/app/components/Icons/IconSettings.vue";
import TaskActionsWrapper from "@/app/components/Task/TaskTable/TaskActionsWrapper.vue";
import TaskTableTooltip from "@/app/components/Task/TaskTable/TaskTableTooltip.vue";
import { useRoute, useRouter } from "@/shared/hooks";
import { useNotification } from "@/shared/hooks/notification";
import { ArchiveTaskDocument, Task } from "@/shared/types/schema.types";
import { taskTableColumns } from "@/shared/utils";

interface TableData {
  row: Task,
  columnId: string
}

export default defineComponent({
  name: "TasksTable",
  components: {
    IconSettings,
    RPriority,
    TaskTableTooltip,
    StatusCell,
    VTable,
    TaskActionsWrapper,
  },
  props: {
    tasks: {
      type: Array as PropType<Task[]>,
      default: () => [] as Task[],
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(_, { emit }) {
    const limitPages = 10;
    const currentPage = ref(0);
    const route = useRoute();
    const router = useRouter();
    const projectId = route.params.id;

    const { mutate: archiveTaskMutation, onError, onDone } = useMutation(ArchiveTaskDocument);
    const { errNotification, successNotification } = useNotification();

    function handlerSortTable(sortData: { dataId: string, sortDirection: string }): void {
      emit("on-sort", sortData);
    }

    function handlerChangePage(page: number): void {
      emit('change-page', page);
    }

    function handlerPin(taskId: string): void {
      console.log("handlerPin", { taskId });
    }

    function handlerEdit(taskId: string): void {
      const editTaskLink = projectId? `/projects/${projectId}/tasks/${taskId}/edit-task` : `/tasks/${taskId}/edit-task`;
      router && router.push(editTaskLink);
    }

    function handlerCopy(taskId: string): void {
      console.log("handlerCopy", { taskId });
    }

    async function handlerArchive(taskId: string): Promise<void> {
      await archiveTaskMutation({ id: taskId });
    }

    function handlerRowClick(data: TableData): void {
      if (data.columnId === '$action') return;
      const editTaskLink = projectId? `/projects/${projectId}/tasks/${data.row.id}` : `/tasks/${data.row.id}`;
      router?.push(editTaskLink);
    }

    onError((err) => {
      errNotification(err);
    });

    onDone((res) => {
      if (!res.data) return;
      emit('on-archive');
      successNotification(`Задача ${res.data.task.archive.name} помещена в архив`);
    });

    return {
      handlerChangePage,
      handlerPin,
      handlerEdit,
      handlerCopy,
      handlerArchive,
      handlerRowClick,
      handlerSortTable,
      columns: taskTableColumns,
      limitPages,
      currentPage,
      projectId,
    };
  },
});
