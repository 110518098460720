


























































































import Vue, { PropType } from "vue";

import {
  addDays,
  addMonth, addYears,
  DateMaskFormat, DateType,
  FormatType, getDatesBetweenToDates, getDateWithFormat,
  getDaysInMonth, getMonth,
  getStringDate, getYear,
  isLeapYear, isNow, mapOfDate, paddingFirstDayInMonth,
  subtractDays, subtractMonth, subtractYears,
} from "@/shared/utils";
import { MONTH_DAY, MONTH_DAY_LEAP, MONTHS, SHORT_WEEK_DAYS } from "@/shared/utils/times/time.constants";


interface Data {
  weekDays: string[];
  focus: boolean;
  disabledRange: boolean;
  rangeDates: DateType[];
}

type DateProps = DateType | null;
export default Vue.extend({
  name: "Calendar",
  props: {
    date: {
      type: [String, Number, Date] as PropType<DateProps>,
      required: true,
    },
    format: {
      type: String as PropType<DateMaskFormat>,
      default: "YYYY-MM-DD",
      validator: (v: DateMaskFormat) => ["YYYY-MM-DD", "YYYY-DD-MM"].includes(v),
    },
    returnFormat: {
      type: String as PropType<FormatType>,
      default: "date",
      validator: (v: FormatType) => ["unix", "date", "format"].includes(v),
    },
    title: {
      type: String as PropType<string>,
      default: "", 
    },
    range: {
      type: Boolean as PropType<boolean>,
      default: false, 
    },
  },
  data(): Data {
    return {
      weekDays: SHORT_WEEK_DAYS,
      focus: false,
      disabledRange: true,
      rangeDates: [],
    };
  },
  computed: {
    rootValue(): Date {
      return new Date(this.date || Date.now());
    },
    inputValue(): string {
      return getDateWithFormat(this.rootValue, this.format as DateMaskFormat);
    },
    currentMonthName(): string {
      const index = this.rootValue.getMonth();
      return MONTHS[index];
    },
    daysInMonthList(): Date[] {
      const date = new Date(this.rootValue);
      return getDaysInMonth(date.getMonth(), date.getFullYear());
    },
    daysInMonth(): number {
      return this.daysList[getMonth(this.rootValue)];
    },
    daysInPrevMonth(): number {
      return this.daysList[getMonth(this.rootValue) === 0 ? 11 : getMonth(this.rootValue) - 1];
    },
    daysList(): number[] {
      const isLeap = isLeapYear(getYear(this.rootValue));
      return isLeap ? MONTH_DAY_LEAP : MONTH_DAY;
    },
    paddingStartDays(): Date[] {
      const total = paddingFirstDayInMonth(getYear(this.rootValue), getMonth(this.rootValue));
      const date = subtractDays(this.daysInMonthList[0]?.getTime(), total);
      return getDatesBetweenToDates(date, this.daysInMonthList[0]?.getTime());
    },
    paddingFinishDays(): Date[] {
      const total = 42 - (this.paddingStartDays.length + this.daysInMonth);
      const date = addDays(
        this.daysInMonthList[this.daysInMonthList.length - 1]?.getTime(),
        total + 1,
      );
      const startDate = new Date(
        addDays(this.daysInMonthList[this.daysInMonthList.length - 1], 1),
      ).getTime();
      return getDatesBetweenToDates(startDate, date);
    },
    countGrid(): number {
      return this.paddingStartDays.length + this.daysInMonth + this.paddingFinishDays.length;
    },
  },
  mounted(): void {
    document.addEventListener("keydown", this.documentKeydownCb);
  },
  beforeDestroy(): void {
    document.removeEventListener("keydown", this.documentKeydownCb);
  },
  methods: {
    handlerNextYear(): void {
      this.handleSelectDate(addYears(this.rootValue, 1));
    },
    handlerPrevYear(): void {
      this.handleSelectDate(subtractYears(this.rootValue, 1));
    },
    handlerNextMonth(): void {
      this.handleSelectDate(addMonth(this.rootValue, 1));
    },
    handlerPrevMonth(): void {
      this.handleSelectDate(subtractMonth(this.rootValue, 1));
    },

    handleSelectDate(d: DateType): void {
      if (this.format) {
        const key = this.returnFormat as FormatType;
        if (!d) return;
        const result = mapOfDate(d, this.format as DateMaskFormat)[key];
        this.$emit("input", result);
      }
    },
    isNow(d: number): boolean {
      return isNow(d, this.rootValue) && !this.rangeDates.length;
    },
    isDateRangeStartAndEnd(d: Date): boolean {
      if (this.rangeDates.length === 2) {
        const item = d.getTime();
        const start = new Date(this.rangeDates[0]).getTime();
        const end = new Date(this.rangeDates[1]).getTime();
        return item === start || item === end;
      }
      return false;
    },
    isDateInRange(d: Date): boolean {
      if (this.rangeDates.length === 2) {
        const item = d.getTime();
        const start = new Date(this.rangeDates[0]).getTime();
        const end = new Date(this.rangeDates[1]).getTime();
        return item >= start && item <= end;
      }
      return false;
    },
    isSelectedMonth(d: Date): boolean {
      return getStringDate(this.rootValue) === getStringDate(d) && !this.rangeDates.length;
    },
  },
});
