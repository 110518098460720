



























































































































import _ from "lodash";
import Vue, { PropType } from "vue";

import NoData from "@/app/components/Base/NoData.vue";
import Pagination from "@/app/components/Base/Pagination.vue";
import IconSettings from "@/app/components/Icons/IconSettings.vue";
import IconTableSort from "@/app/components/Icons/IconTableSort.vue";

import { TableColumns } from "./table.props";

type ItemKayMap = Record<string, unknown>;

type SortType = "ASC" | "DESC";


interface TableData {
  tableData: ItemKayMap[];
  query: string;
  prevKey: string;
  orderDesc: boolean;
  sortedCol: {
    sortType: SortType | undefined;
    dataId: string | undefined;
  };
}

const isTrueSet = (value: string | null | undefined): boolean => value === "true";
export default Vue.extend({
  name: "VTable",
  components: {
    IconSettings,
    IconTableSort,
    NoData,
    Pagination,
  },
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false, 
    },
    showTools: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false, 
    },
    serverSideEvents: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false, 
    },
    isOdd: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false, 
    },
    noDataText: {
      type: String as PropType<string>,
      default: "Данных нет",
      required: false, 
    },
    items: {
      type: Array as PropType<ItemKayMap[]>,
      default: () => [],
      required: true, 
    },
    columns: {
      type: Array as PropType<TableColumns<unknown>[]>,
      default: () => [],
      required: true,
    },
    pagination: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false, 
    },
    totalItems: {
      type: Number as PropType<number>,
      default: 0,
      required: false, 
    },
    totalPages: {
      type: Number as PropType<number>,
      default: 0,
      required: false, 
    },
    currentPage: {
      type: Number as PropType<number>,
      default: 0,
      required: false, 
    },
    itemsOnPage: {
      type: Number as PropType<number>,
      default: 10,
      required: false, 
    },
  },
  data(): TableData {
    return {
      tableData: [],
      query: "",
      prevKey: "id",
      orderDesc: false,
      sortedCol: {
        sortType: undefined,
        dataId: undefined, 
      },
    };
  },
  beforeDestroy(): void {
    this.resetSortedCol();
  },
  computed: {
    minHeightClasses(): string {
      const heightRow = 50;
      const rowsItemsQuantity = 12;
      return `min-height: calc(${heightRow}px * ${rowsItemsQuantity})`;
    },
    filteredItems(): unknown[] {
      const searchValueReplaced = this.query.replaceAll(" ", "").toLowerCase();
      return _.filter(this.tableData, (user) =>
        _.find(user, (prop) => {
          return new RegExp(searchValueReplaced, "i").test(prop);
        }),
      );
    },
  },
  watch: {
    items:{
      handler(value): void{
        this.tableData = value;
      },
      immediate: true,
    },
  },
  methods: {
    sortedIconFill(dataId: string, sortDirection: string): string {
      return this.sortedCol.dataId === dataId ? this.sortedCol.sortType === sortDirection ? "#C0D6F6" : "#3D75E4" : "#C0D6F6";
    },
    resetSortedCol(): void {
      this.sortedCol = {};
    },
    handlerShowSettings(): void {
      this.$emit("on-show-settings");
    },
    handlerChangePage(page: number): void {
      this.$emit("change-page", page);
    },
    getColName(
      dataId: string,
      col: any,
      deep: string[] | string[][],
      empty: string | number,
      format: (col: unknown) => number | string,
    ): string | number {
      let field = col[dataId];
      let result = format ? format(col) : field;
      if (!result) result = empty;
      return result;
    },
    isPrivateColumn(dataId: string): boolean {
      return Boolean(dataId.startsWith("$"));
    },
    handlerRowClick(row: unknown, columnId: string): void {
      this.$emit("row-click", {
        row,
        columnId, 
      });
    },
    sortTableData(params: any, type: string): void {
      if (!this.filteredItems.length) return;
      let { key, sorted, deep, col } = params;
      if (!sorted) return;
      let deepKey = deep && deep.includes(",") ? deep.split(",")[0] : deep || "";
      if (this.serverSideEvents) {
        const isEqualValue = _.isEqual(this.sortedCol, {
          dataId: col.dataId,
          sortType: type, 
        });
        if (isEqualValue) {
          this.$emit("on-sort", {
            dataId: col.dataId,
            sortDirection: "NOT_SET", 
          });
          this.resetSortedCol();
          return;
        }
        this.$emit("on-sort", {
          dataId: col.dataId,
          sortDirection: type, 
        });
        this.sortedCol = {
          sortType: type,
          dataId: col.dataId, 
        };
        return;
      }
      if (this.prevKey === key) {
        this.tableData.reverse();
        this.orderDesc = !this.orderDesc;
      }
      else {
        this.tableData = _.sortBy(this.tableData, (el) => {
          return key ? el[key]?.[deepKey] : key && el[key] ? el[key] : el;
        });
        this.orderDesc = false;
        if (key) this.prevKey = key;
      }
    },
    clearQuery(): void {
      this.query = "";
    },
    onCreate(elm: HTMLDivElement): void {
      elm.style.opacity = "0";
    },
    onData(elm: HTMLDivElement): void {
      this.tableData = this.items;
      elm.style.opacity = "1";
    },
  },
  mounted(): void{
    this.tableData = this.items;
  },
});
