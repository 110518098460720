import { computed, ref } from "@vue/composition-api";

import { DEFAULT_TASK_FILTER_DATA } from "@/shared/constants/components/Task/TaskView";
import { TaskViewSelectorEnum } from "@/shared/enums/components/Task/TaskViewSelector.enum";
import { ModifiedTaskFilter } from "@/shared/types/components/Task/Task";
import {
  ColumnSorting,
  GetTaskBoardQuery,
  GetTaskSheetQuery,
  GetTasksQuery,
  SortDirection,
  TaskSheetColumnCode,
} from "@/shared/types/schema.types";
import { getFilterQuery } from "@/shared/utils";

interface TasksData extends Object {
  taskSheet?: GetTaskSheetQuery["taskSheet"];
  taskBoard?: GetTaskBoardQuery["taskBoard"];
  tasks?: GetTasksQuery["tasks"];
}

export function useTasksData() {
  const tasksData = ref<TasksData>({
    taskSheet: undefined,
    taskBoard: undefined,
    tasks: undefined,
  });
  const loading = ref<boolean>(false);

  const columnSorting = ref<ColumnSorting>({
    columnCode: TaskSheetColumnCode.Id,
    sortDirection: SortDirection.Asc,
  });

  const filterData = ref<ModifiedTaskFilter>(_.cloneDeep(DEFAULT_TASK_FILTER_DATA));
  const filterQuery = computed(() => getFilterQuery(filterData.value));
  const taskView = ref<TaskViewSelectorEnum>(TaskViewSelectorEnum.TASKS);
  return {
    tasksData,
    loading,
    filterData,
    filterQuery,
    taskView,
    columnSorting,
  };
}
