









import { computed, defineComponent, PropType } from "@vue/composition-api";

import { TaskStatus } from "@/shared/types/schema.types";

export default defineComponent({
  name: "status-cell",
  props: { status: { type: Object as PropType<TaskStatus> } },
  setup(props) {
    const statusShortName = computed(() =>
      props.status ? props.status.name[0].toUpperCase() : "-",
    );
    const getCellStyles = computed(() => ({ backgroundColor: props.status?.colorValue || "" }));
    return {
      statusShortName,
      getCellStyles,
    };
  },
});
