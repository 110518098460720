














import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, PropType } from "@vue/composition-api";

import TaskTableTooltip from "@/app/components/Task/TaskTable/TaskTableTooltip.vue";
import { useRouter } from "@/shared/hooks";
import { useNotification } from "@/shared/hooks/notification";
import { ArchiveTaskDocument, Task, TogglePinTaskDocument, UnzipTaskDocument } from "@/shared/types/schema.types";
import { copyTextToClipboard } from "@/shared/utils";

export default defineComponent({
  name: "TaskActionsWrapper",
  components: { TaskTableTooltip },
  props: {
    task: {
      type: Object as PropType<Task>,
      default: () => null,
    },
    projectId: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const { errNotification, successNotification } = useNotification();
    const router = useRouter();

    const { mutate: archiveTaskMutation } = useMutation(ArchiveTaskDocument);
    const { mutate: unzipTaskMutation } = useMutation(UnzipTaskDocument);
    const { mutate: togglePinTaskMutation } = useMutation(TogglePinTaskDocument);

    const taskId = computed(() => props.task?.id);

    // Отредактировать задачу
    function handleEdit() {
      const editLink = props.projectId? `/projects/${props.projectId}/tasks/${taskId.value}/edit-task` : `/tasks/${taskId.value}/edit-task`;
      router.push(editLink);
    }

    // Архивировать задачу
    async function handleZip() {
      try {
        await archiveTaskMutation({ id: taskId.value });
        successNotification(`Задача ${props.task.name} перемещена в архив`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    function handleCopy() {
      const taskLink = props.projectId? `/projects/${props.projectId}/tasks/${taskId.value}` : `/tasks/${taskId.value}`;
      copyTextToClipboard(`${window.location.origin}${taskLink}`, () => successNotification('Ссылка на задачу успешно скопирована в буфер'), (text: string) => errNotification(text));
    }

    //Убрать из архива
    async function handleUnzip() {
      try {
        await unzipTaskMutation({ id: taskId.value });
        successNotification(`Задача ${props.task.name} восстановлена из архива`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    // Прикрепить задачу
    async function handlePin() {
      try {
        await togglePinTaskMutation({ id: props.task?.id });
        successNotification(`Задача ${props.task.name} ${props.task?.pinned? "откреплена" : "закреплена"}`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    return {
      handleZip,
      handleEdit,
      handleCopy,
      handleUnzip,
      handlePin,
    };
  },
});
