





































































import { computed, defineComponent, onMounted, PropType, watch } from "@vue/composition-api";

import Calendar from "@/app/components/Base/Calendar.vue";
import Kanban from "@/app/components/Task/Kanban/Kanban.vue";
import TaskFilter from "@/app/components/Task/TaskFilter.vue";
import TasksTable from "@/app/components/Task/TaskTable/TasksTable.vue";
import TaskViewSelector from "@/app/components/Task/TaskViewSelector.vue";
import { TaskViewSelectorEnum } from "@/shared/enums/components/Task/TaskViewSelector.enum";
import { useRoute } from "@/shared/hooks";
import { PAGE_SIZE, usePagination } from "@/shared/hooks/pagination";
import { useTasksData } from "@/shared/hooks/tasks";
import {
  fetchCalendarTasks,
  fetchKanbanTasks,
  fetchProjectCalendarTasks,
  fetchProjectKanbanTasks,
  fetchProjectTasks,
  fetchTasks,
} from "@/shared/requests";
import { ModifiedTaskFilter } from "@/shared/types/components/Task/Task";
import { GetProjectShortQuery, SortDirection, TaskSheetColumnCode } from "@/shared/types/schema.types";
import { TableDataIds } from "@/shared/utils";

export default defineComponent({
  props: {
    project: {
      type: Object as PropType<GetProjectShortQuery["project"]>,
      default: null,
    },
  },
  components: {
    TaskViewSelector,
    TasksTable,
    Kanban,
    Calendar,
    TaskFilter,
  },
  name: "TaskView",
  setup(props) {
    const route = useRoute();
    const projectId = route.params.id;

    const DEFAULT_PAGINATION = {
      pageSize: PAGE_SIZE,
      pageNumber: 0, 
    };

    const { pagination, changePage } = usePagination();

    const { tasksData, loading, filterData, filterQuery, taskView, columnSorting } = useTasksData();

    const taskSheetParams = computed(() => {
      return {
        id: projectId,
        columnSorting: { ...columnSorting.value },
        taskFilter: filterQuery.value,
        pagination: pagination.value,
      };
    });

    async function filterTasks(ev: ModifiedTaskFilter) {
      filterData.value = ev;
      await fetchTaskData();
    }

    async function changeTaskView(val: TaskViewSelectorEnum) {
      taskView.value = val;

      tasksData.value = {
        taskSheet: undefined,
        taskBoard: undefined,
        tasks: undefined,
      };
      pagination.value = _.cloneDeep(DEFAULT_PAGINATION);

      await fetchTaskData();
    }

    async function handlerSortTable({
      sortDirection,
      dataId,
    }: { dataId: TableDataIds, sortDirection: string }): Promise<void> {
      /* @NOTE - временное решение, т.к. пока не реализовано получение колонок  ссервера */
      if (sortDirection === SortDirection.NotSet) {
        columnSorting.value = {
          columnCode: TaskSheetColumnCode.Id,
          sortDirection: SortDirection.Asc,
        };
      }
      else {
        columnSorting.value = {
          columnCode: TableDataIds[dataId] as TaskSheetColumnCode,
          sortDirection: sortDirection as SortDirection,
        };
      }

      await fetchTaskData();
    }

    async function handlerChangePage(pageNumber = 0): Promise<void> {
      changePage(pageNumber);
      await fetchTaskData();
    }

    async function loadKanbanPage(direction: 'prev' | 'next') {
      if (direction === 'prev') {
        pagination.value.pageNumber--;
      }
      else {
        pagination.value.pageNumber++;
      }

      tasksData.value.taskBoard = projectId? await fetchProjectKanbanTasks(taskSheetParams.value) : await fetchKanbanTasks(_.omit(taskSheetParams.value, "id"));
    }

    async function fetchTaskData(): Promise<void> {
      try {
        loading.value = true;

        switch (taskView.value) {
          case TaskViewSelectorEnum.TASKS: {
            tasksData.value.taskSheet = projectId? await fetchProjectTasks(taskSheetParams.value) : await fetchTasks(_.omit(taskSheetParams.value, "id"));
            break;
          }
          case TaskViewSelectorEnum.KANBAN: {
            tasksData.value.taskBoard= projectId? await fetchProjectKanbanTasks(taskSheetParams.value) : await fetchKanbanTasks(_.omit(taskSheetParams.value, ["id", "columnSorting"]));
            break;
          }
          case TaskViewSelectorEnum.CALENDAR: {
            tasksData.value.tasks = projectId? await fetchProjectCalendarTasks(taskSheetParams.value) : await fetchCalendarTasks(_.omit(taskSheetParams.value, ["id", "columnSorting", "pagination"]));
            break;
          }
        }
      }
      catch (err) {
        console.error(err);
      }
      finally {
        loading.value = false;
      }
    }

    const createTaskLink = computed(() => projectId? `/projects/${projectId}/new-project-task` : '/new-task');
    const leftPaginationIsDisabled = computed(() => pagination.value.pageNumber === 0);
    const rightPaginationIsDisabled = computed(() => pagination.value.pageNumber === ((tasksData.value.taskBoard?.sheetInfo?.totalPages || 0) - 1));

    onMounted(async() => {
      await fetchTaskData();
    });

    watch(
      () => props.project as GetProjectShortQuery["project"],
      (val) => {
        if (!val) return;
        filterQuery.value.isArchived = val.archived;
      },
      {
        immediate: true,
        deep: true,
      },
    );

    return {
      filterData,
      projectId,
      createTaskLink,
      handlerChangePage,
      handlerSortTable,
      changeTaskView,
      filterTasks,
      tasksData,
      taskView,
      loading,
      pagination,
      loadKanbanPage,
      leftPaginationIsDisabled,
      rightPaginationIsDisabled,
      fetchTaskData,
    };
  },
});
