





















































































import { defineComponent, PropType, ref, watch } from "@vue/composition-api";

import DateRangePicker from "@/app/components/Base/DateRangePicker.vue";
import FilterModal from "@/app/components/Base/FilterModal.vue";
import VModal from "@/app/components/Base/Modal.vue";
import VSelect from "@/app/components/Base/VSelect.vue";
import UserSelect from "@/app/components/User/UserSelect.vue";
import { DEFAULT_FILTER_DATA } from "@/shared/constants/components/Task/TaskFilter";
import { useTaskPriorities, useTaskStatuses, useUsers } from "@/shared/hooks/fetchers";
import { useFindUsers } from "@/shared/hooks/users/useFindUsers";
import { ComboItem } from "@/shared/types/components/Base/VSelectItem";
import { ModifiedTaskFilter } from "@/shared/types/components/Task/Task";
import { TaskFilter } from "@/shared/types/schema.types";

type ComboKey = Extract<keyof TaskFilter, "priorityCode" | "statusId" | "authorId" | "executorId">;
type RangePickerKey = Extract<keyof TaskFilter, "createdAtInterval" | "deadlineInterval">;

export default defineComponent({
  name: "TaskFilter",
  props: {
    isArchivedProject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<ModifiedTaskFilter>,
      default: DEFAULT_FILTER_DATA, 
    }, 
  },
  components: {
    VModal,
    DateRangePicker,
    VSelect,
    UserSelect,
    FilterModal,
  },
  setup(props, { emit }) {

    const taskFilter = ref<ModifiedTaskFilter>(_.cloneDeep(DEFAULT_FILTER_DATA));

    const { findUser, usersParams } = useFindUsers();
    const { data: users } = useUsers(usersParams.value);
    const { data: taskPriorities } = useTaskPriorities();
    const { data: taskStatuses } = useTaskStatuses();

    const filterModalIsShown = ref(false);

    const searchByText = _.debounce(() => {
      emit("input", taskFilter.value);
    }, 200);

    function showFilterModal() {
      filterModalIsShown.value = true;
    }

    const changeSelect = (k: ComboKey, ev: ComboItem | null) => {
      if (!taskFilter?.value || !k) return;
      taskFilter.value = {
        ...taskFilter.value,
        [k]: (ev && ev.toString()) || null,
      };
    };

    const changeRangePicker = (k: RangePickerKey, ev: number[]) => {
      if (!taskFilter.value.createdAtInterval) return;
      taskFilter.value[k].begin = ev[0];
      taskFilter.value[k].end = ev[1];
    };

    const clearFilter = () => {
      emit("input", _.cloneDeep(DEFAULT_FILTER_DATA));
    };

    const applyFilter = () => {
      filterModalIsShown.value = false;
      emit("input", taskFilter.value);
    };

    function closeFilterModal() {
      taskFilter.value = _.cloneDeep(props.value);
      filterModalIsShown.value = false;
    }

    watch(
      () => props.value as ModifiedTaskFilter,
      (val) => {
        if (!val) return;
        taskFilter.value = props.isArchivedProject? {
          ...val,
          isArchived: true, 
        } : _.cloneDeep(val);
      },
      {
        deep: true,
        immediate: true,
      },
    );

    return {
      taskFilter,
      searchByText,
      findUser,
      showFilterModal,
      filterModalIsShown,
      taskPriorities,
      taskStatuses,
      changeSelect,
      users,
      changeRangePicker,
      clearFilter,
      applyFilter,
      closeFilterModal,
    };
  },
});
