import { ModifiedTaskFilter } from "@/shared/types/components/Task/Task";

export const DEFAULT_FILTER_DATA: ModifiedTaskFilter = {
  authorId: null,
  chars: null,
  createdAtInterval: {
    begin: undefined,
    end: undefined,
  },
  executorId: null,
  priorityCode: null,
  statusId: null,
  deadlineInterval: {
    begin: undefined,
    end: undefined,
  },
  isArchived: false,
};
