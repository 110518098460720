




























































import { computed, defineComponent, PropType } from "@vue/composition-api";

import TaskActionsWrapper from "@/app/components/Task/TaskTable/TaskActionsWrapper.vue";
import TaskTableTooltip from "@/app/components/Task/TaskTable/TaskTableTooltip.vue";
import { useRouter } from "@/shared/hooks";
import { Task, TaskStatus } from "@/shared/types/schema.types";
import { formatDateTimeForDisplay, formatFio } from "@/shared/utils";

export default defineComponent({
  name: "KanbanCard",
  components: {
    TaskTableTooltip,
    TaskActionsWrapper, 
  },
  props: {
    status: {
      type: Object as PropType<TaskStatus>,
      required: true, 
    },
    task: {
      type: Object as PropType<Task>,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const taskDeadline = computed(() => formatDateTimeForDisplay(props.task.deadline));
    const router = useRouter();

    const executorFullName = computed(
      () => formatFio(props.task?.executor?.fullName),
    );
    const authorFullName = computed(
      () => formatFio(props.task?.author?.fullName),
    );

    function handleClick(): void {
      const projectId = props.task?.project?.id;
      const editTaskLink = projectId? `/projects/${projectId}/tasks/${props.task.id}` : `/tasks/${props.task.id}`;
      router.push(editTaskLink);
    }

    return {
      executorFullName,
      authorFullName,
      taskDeadline,
      handleClick,
    };
  },
});
